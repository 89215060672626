/* App.css (or create a new CSS file for login styling) */

/* Global reset and basic styling */

  
  
  /* Login container */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Login form */
  .login-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .login-form form {
    display: flex;
    flex-direction: column;
  }
  
  .login-form input[type="text"],
  .login-form input[type="password"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-size: 16px;
  }
  
  .login-form input[type="text"]:focus,
  .login-form input[type="password"]:focus {
    outline: none;
    border-color: #6e9cff;
  }
  
  .login-form button {
    background-color: #6e9cff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-form button:hover {
    background-color: #4c7bd9;
  }
  